import packageJson from '../package.json';
export default {
    VERSION: packageJson.version,
    API_URL: 'https://api.infotechnic.es/',

    /* SELECT PARTNER */
    PARTNER: 'next'
    // PARTNER: 'andel'
    // PARTNER: 'bestdrive'
    // PARTNER: 'cga2'
    // PARTNER: 'optimacar'
    // PARTNER: 'voltaf'
}

/* THEME SELECTOR
*  settings.js
*  theme.scss
*  router.js
*  App.vue (logo)
* */
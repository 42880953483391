<template>
  <v-app-bar
      app
      :dark="theme.dark"
      v-if="logged"
  >

    <!-- Logo -->
    <router-link to="/bulletins" @click="location.reload()">
      <img v-if="partner === 'next'" src="../assets/logo-blanco-naranja-horizontal.png" height="30px">
      <img v-if="partner === 'andel'" src="../assets/partners/andel.svg" height="30px">
      <img v-if="partner === 'bestdrive'" src="../assets/partners/bestdrive.svg" height="60px">
      <img v-if="partner === 'cga2'" src="../assets/partners/cga.png" height="55px">
      <img v-if="partner === 'optimacar'" src="../assets/partners/optimacar.png" height="50px">
      <img v-if="partner === 'voltaf'" src="../assets/partners/voltaf.png" height="55px">
    </router-link>

    <v-spacer></v-spacer>

    <!-- Menu -->
    <div class="d-none d-lg-inline-flex">

      <v-btn
          text
          active-class="no-active"
          :color="currentPage.includes('bulletins') ? 'primary' : ''"
          to="/bulletins"
      >
        <v-icon class="mr-2">mdi-book</v-icon>
        {{ TEXT('bulletins') }}
      </v-btn>

      <v-btn
          text
          active-class="no-active"
          :color="currentPage.includes('academy') ? 'primary' : ''"
          v-if="language == 'fr'"
          to="/academy/fr"
      >
        <v-icon class="mr-2">mdi-school</v-icon>
        {{ TEXT('learning') }}
      </v-btn>

      <v-menu offset-y open-on-hover v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              active-class="no-active"
              :color="currentPage.includes('academy') ? 'primary' : ''"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon class="mr-2">mdi-school</v-icon>
            {{ TEXT('learning') }}
          </v-btn>
        </template>
        <v-list nav >
          <v-list-item to="/academy/system">
            <v-list-item-title>
              <v-icon class="mr-2">mdi-car-brake-abs</v-icon>
              {{ TEXT('system_courses') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/academy/vehicle">
            <v-list-item-title>
              <v-icon class="mr-2">mdi-car</v-icon>
              {{ TEXT('vehicle_courses') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/academy/fr" v-if="language=='fr'">
            <v-list-item-title>
              <a class="fi fi-fr mr-4"></a>Cours en français
            </v-list-item-title>
          </v-list-item>
          <v-list-item to="/academy/pt" v-if="language=='pt'">
            <v-list-item-title>
              <a class="fi fi-pt mr-4"></a>Cursos em português
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
          text
          active-class="no-active"
          :color="currentPage.includes('procedure') ? 'primary' : ''"
          to="/procedure"
      >
        <v-icon class="mr-2">mdi-speedometer</v-icon>
        {{ TEXT('processes') }}
      </v-btn>

      <!--      <v-btn-->
      <!--          text-->
      <!--          active-class="no-active"-->
      <!--          :color="currentPage.includes('bot') ? 'primary' : ''"-->
      <!--          to="/bot"-->
      <!--      >-->
      <!--        <v-icon class="mr-2">mdi-robot-angry</v-icon>-->
      <!--        CHATBOT-->
      <!--      </v-btn>-->

      <v-btn
          text
          active-class="no-active"
          :color="currentPage.includes('IA') ? 'primary' : ''"
          to="/IA"
      >
        <v-icon class="mr-2">mdi-robot</v-icon>
        IA
      </v-btn>

      <v-btn
          text
          active-class="no-active"
          :color="currentPage.includes('partners') ? 'primary' : ''"
          to="/partners"
      >
        <v-icon class="mr-2">mdi-star-shooting</v-icon>
        {{ TEXT('our_brands') }}
      </v-btn>

      <!-- User Menu -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              text
              color="primary"
              min-width="0"
              class="px-0"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-card dark>
          <v-list-item class="primary--text">
            <v-list-item-title>
              <language-selector></language-selector>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="primary--text">
            <v-list-item-title>{{ userName }}</v-list-item-title>
            <v-btn
                icon
                to="/"
                color="primary"
            >
              <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
          </v-list-item>
        </v-card>
      </v-menu>


    </div>

    <!-- Mobile -->
    <v-app-bar-nav-icon
        @click.stop="$store.commit('SET_MENU', !menu)"
        class="d-inline-flex d-lg-none primary--text"
    ></v-app-bar-nav-icon>

  </v-app-bar>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import LanguageSelector from "@/components/LanguageSelector.vue";

export default {
  name: "TopBar",
  components: {LanguageSelector},
  computed: {
    currentPage() {
      return this.$route.path;
    },
    userName() {
      return sessionStorage.workshop;
    },
    ...mapGetters(['TEXT']),
    ...mapState([
      'partner',
      'theme',
      'logged',
      'menu',
      'language'
    ])
  }
}
</script>

<style scoped>
a {
  text-decoration: none !important;
}
</style>
import axios from "axios";
import settings from "@/settings";

const API_URL = settings.API_URL

export default {

    /* MAIN */
    LOGIN(username, password) {
        return axios.post(API_URL + 'bridge/login.php', {
            partner: settings.PARTNER,
            username: username,
            password: password
        })
    },
    LOGOUT() {
        return axios.post(API_URL + 'bridge/logout.php', {
            token: sessionStorage.token
        })
    },
    GET_SESSION(token) {
        return axios.post(API_URL + 'bridge/getSession.php', {
            token
        })
    },
    /* BULLETINS */
    GET_MAKERS() {
        return axios.post(API_URL + 'tecdoc/listMakers.php', {
            partner: settings.PARTNER,
            token: sessionStorage.token
        })
    },
    GET_MODELS(maker) {
        return axios.post(API_URL + 'tecdoc/listModels.php', {
            maker: maker,
            token: sessionStorage.token
        })
    },
    GET_VEHICLES(modelID) {
        return axios.post(API_URL + 'tecdoc/listVehicles.php', {
            model: modelID,
            token: sessionStorage.token
        })
    },
    GET_HISTORY() {
        return axios.post(API_URL + 'bridge/listHistory.php', {
            user: sessionStorage.user,
            token: sessionStorage.token
        })
    },
    SELECT_VEHICLE(makerID, modelID, vehicleID) {
        axios.post(API_URL + 'bridge/selectVehicle.php', {
            makerID: makerID,
            modelID: modelID,
            vehicleID: vehicleID,
            user: sessionStorage.user,
            token: sessionStorage.token
        })
    },
    COUNT_BULLETINS(vehicleID) {
        return axios.post(API_URL + 'bridge/countBulletins.php', {
            vehicleID: vehicleID,
            partner: settings.PARTNER,
            token: sessionStorage.token
        })
    },
    GET_BULLETINS(vehicleID) {
        return axios.post(API_URL + 'bridge/listBulletins.php', {
            vehicleID: vehicleID,
            partner: settings.PARTNER,
            token: sessionStorage.token
        })
    },
    GET_NEW_SEARCH(maker, str) {
        return axios.post(API_URL + 'bridge/searchDTC.php', {
            maker,
            search: str,
            token: sessionStorage.token
        })
    },
    SUBMIT_FEEDBACK(vehicleID, bulletinID, comment) {
        axios.post(API_URL + 'bridge/feedback.php', {
            partner: settings.PARTNER,
            username: sessionStorage.username,
            token: sessionStorage.token,
            vehicleID: parseInt(vehicleID),
            bulletinID: parseInt(bulletinID),
            comment
        })
    },

    /* HAYNES PRO */
    GET_HAYNES_LIST(vehicle, language) {
        return axios.post(API_URL + 'haynes/list.php', {
            vrid: sessionStorage.haynes_vrid,
            vehicle, language
        })
    },

    GET_HAYNES_DETAIL(type, id, language) {
        return axios.post(API_URL + 'haynes/detail.php', {
            vrid: sessionStorage.haynes_vrid,
            type, id, language
        })
    },

    /* FORMACIÓN */
    GET_LESSONS_FILES(dir) {
        return axios.post(API_URL + 'bridge/scandir.php', {
            token: sessionStorage.token,
            dir,
        })
    },

    /* COUCHDB */
    GET_PROCEDURE_LIST() {
        return axios.post(API_URL + 'couchdb/listProcedures.php', {
            partner: settings.PARTNER,
            token: sessionStorage.token
        })
    },

    GET_PROCEDURE(doc) {
        return axios.post(API_URL + 'couchdb/getProcedure.php', {
            partner: settings.PARTNER,
            token: sessionStorage.token,
            doc
        })
    },

    /* GPT */
    ASK_TO_GPT(prompt) {
        return axios.post(API_URL + 'gpt/chatRequest.php', {
            prompt
        })
    },

}

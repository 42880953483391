<template>
  <div class="container-fluid">
    <div class="d-inline-flex">
      <img :src="'https://infotechnic.tecnoclub.org/logos/'+vehicle.maker.toLowerCase()+'.png'" alt=""
           class="img-fluid mr-2"
           style="height: 50px">
      <h1>{{ vehicle.maker }} {{ vehicle.model }} {{ vehicle.motor }}</h1>
    </div>
    <h2 class="card-subtitle">
      <img src="https://assets.infotechnic.es/categories/haynes.jpg" alt="" height="100">
    </h2>

    <v-tabs v-model="tab">
      <v-tab :disabled="!TSB">SmartFIX</v-tab>
      <v-tab :disabled="!RECALL">Recalls</v-tab>
      <v-tab :disabled="!CASE">SmartCASE</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-list dark v-if="TSB">
              <v-list-item
                  link
                  v-for="item in TSB.SISTEMAS"
                  :key="item.Id"
                  @click="select('TSB', item.Id)"
              >
                {{ item.Descripcion }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-list dark v-if="RECALL">
              <v-list-item
                  link
                  v-for="item in RECALL.SISTEMAS"
                  :key="item.Id"
                  @click="select('RECALL', item.Id)"
              >
                {{ item.Descripcion }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-list dark v-if="CASE">
              <v-list-item
                  link
                  v-for="item in CASE.SISTEMAS"
                  :key="item.Id"
                  @click="select('CASE', item.Id)"
              >
                {{ item.Descripcion }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <HaynesViewer ref="viewer"></HaynesViewer>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import api from "@/api";
import HaynesViewer from "@/components/bulletins/HaynesViewer.vue";

export default {
  name: "HaynesSelector",
  components: {HaynesViewer},
  data() {
    return {
      TSB: null,
      RECALL: null,
      CASE: null,
      tab: null,
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'vehicle',
      'category',
      'language'
    ]),
  },
  methods: {
    listHaynes() {
      api.GET_HAYNES_LIST(this.vehicle.extcode, this.language)
          .then(response => {
            console.log(response.data)
            let pack = response.data.SMART_PACK
            this.TSB = pack.filter(item => {
              return item['Categoria'] === 'TSB'
            })[0]
            this.RECALL = pack.filter(item => {
              return item['Categoria'] === 'RECALL'
            })[0]
            this.CASE = pack.filter(item => {
              return item['Categoria'] === 'CASE'
            })[0]
          })
    },
    select(type, id) {
      this.$refs.viewer.show(type, id)
    }
  },
  created() {
    this.listHaynes()
    this.tab = -(this.category)
  }
}
</script>

<style scoped>

</style>
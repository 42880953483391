<template>
  <v-dialog
      v-model="viewer"
      max-width="1200"
  >
    <v-card height="80vh">
      <v-card-title class="primary white--text text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text class="p-3 primary">
        <!--        <iframe height="100%" width="100%" :src="url" style="border: none"></iframe>-->
        <vue-pdf-embed
            :source="url"
            @rendered="handleDocumentRender"
        ></vue-pdf-embed>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: "DocsViewer",
  props: ['title', 'url'],
  components: {
    VuePdfEmbed
  },
  data() {
    return {
      viewer: false
    }
  },
  computed: {
    isPDF() {
      return this.url.split('.').pop().toLowerCase() === 'pdf';
    },
    isMP4() {
      return this.url.split('.').pop().toLowerCase() === 'mp4';
    }
  },
  methods: {
    show() {
      this.viewer = true
    },
    handleDocumentRender() {
      this.isLoading = false
      this.pageCount = this.$refs.pdfRef.pageCount
    },
  }

}
</script>

<style scoped>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
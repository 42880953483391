<template>
  <v-card elevation="5" class="">
    <v-sheet
        color="primary"
        :dark="theme.dark"
    >
      <v-card-title>
        {{ bulletin.title }}
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn small
                   v-bind="attrs"
                   v-on="on">
              <v-icon left>mdi-sync-alert</v-icon>
              {{ TEXT('send_suggestion') }}
            </v-btn>

          </template>

          <v-form @submit.prevent="submitFeedback">
            <v-card>
              <v-card-title class="text-h5">
                <v-icon left>mdi-sync-alert</v-icon>
                {{ TEXT('send_suggestion') }}
              </v-card-title>

              <v-card-text>
                <v-text-field
                    v-model="comment"
                    :label="TEXT('suggestion')"
                    counter
                    autofocus
                    maxlength="255"
                ></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    type="submit"
                >
                  {{TEXT('thank_you')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>


        </v-dialog>
      </v-card-title>
      <v-card-subtitle v-text="bulletin.tags"></v-card-subtitle>
    </v-sheet>
    <v-card-text>
      <h5>{{ TEXT('issue') }}</h5>
      <p v-html="bulletin.trouble"></p>
      <h5>{{ TEXT('cause') }}</h5>
      <p v-html="bulletin.reason"></p>
      <h5>{{ TEXT('solution') }}</h5>
      <p v-html="bulletin.solution"></p>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import api from "@/api";

export default {
  name: "bulletin-viewer",
  props: ['bulletin'],
  data() {
    return {
      dialog: false,
      comment: ''
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'theme',
      'vehicle'
    ]),
  },
  methods: {
    submitFeedback() {
      api.SUBMIT_FEEDBACK(this.vehicle.vehicleID, this.bulletin.bulletin, this.comment)
      this.comment = ''
      this.dialog = false
    },
  }
}
</script>

<style>
.card-body img {
  max-width: 400px;
}

.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
<template>
  <v-dialog v-model="viewer" max-width="1200">
    <v-card height="80vh">

      <v-card-title class="white--text text-h5" style="background-color: #439639">
        <img src="@/assets/haynes.gif" alt="">
      </v-card-title>

      <v-card-text class="text-center pt-16" v-if="loading">
        <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </v-card-text>

      <v-card-text v-else class="p-3 overflow-scroll">


          <div v-for="(section, index) in detail" :key="index">
            <template v-if="index !== detail.length -1">
              <h4>{{ section['Descripcion'] }}</h4>
              <div v-for="(item, index) in section['Boletines']" :key="index">
                <span>{{ item['Texto'] }}</span><small>{{ item['Comentario'] }}</small>
                <v-divider v-if="item['Imagen']"></v-divider>
                <img class="text-center img-fluid" :src="item['Imagen']" alt="">
              </div>
              <v-divider></v-divider>
            </template>
            <template v-else>
              <v-card color="warning">
                <v-card-title><v-icon left>mdi-alert</v-icon>{{ section['Descripcion'] }}</v-card-title>
                <v-card-text>
                  <span v-for="(item, index) in section['Boletines']" :key="index">{{ item['Texto'] }} <small>{{ item['Comentario'] }}</small><br></span>
                </v-card-text>
              </v-card>
            </template>
          </div>






      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";
import {mapState} from "vuex";

export default {
  name: "HaynesViewer",
  data(){
    return {
      viewer: false,
      loading: true,
      detail: null
    }
  },
  computed: {
    ...mapState([
      'language',
    ]),
  },
  methods: {
    show(type, id) {
      this.loading = true
      this.viewer = true
      api.GET_HAYNES_DETAIL(type, id, this.language)
          .then(response => {
            console.log(response.data)
            this.detail = response.data['DETALLE']
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
<template>
  <div>

  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "auto-login",
  data() {
    return {
      loading: true
    }
  },
  methods: {
    checkSession() {
      let token = this.$route.params.token.replace(/_/g, "/")
      api.GET_SESSION(token)
          .then(response => {
            let session = response.data.session
            if (session['OK']) {
              sessionStorage.username = session['USUARIO']['USUARIO']
              sessionStorage.user = session['USUARIO']['ID']
              sessionStorage.workshop = session['USUARIO']['NOMBRE_TALLER']
              sessionStorage.token = token
              sessionStorage.haynes_vrid = response.data.haynes.HEYNES_SESSION.vrid
              this.$emit('login', session['USUARIO']);
            } else {
              this.$emit('logout');
            }
          })
    }
  },
  created() {
    this.checkSession()
  }
}
</script>

<template>
  <v-container>
    <h1>
      <v-icon left x-large color="black" class="pb-2">mdi-robot</v-icon>
      IA
    </h1>
    <v-alert
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
        class="mb-5"
    >
      ADVERTENCIA:
      La inteligencia artificial proporcionará respuestas que pueden resultar útiles, sin embargo estas no estan supervisadas y pueden contener errores o información incorrecta. Por ello es importante tenerlo en consideración a la hora de emprender acciones a partir de la respuesta. Ante cualquier duda, os recomendamos consultar a nuestros técnicos de NextAssist.
    </v-alert>
    <v-row>
      <v-col
          cols="12"
          md="6"
      >
        <v-textarea
            name="ask"
            label="Consulta"
            v-model="query"
            hint=""
            outlined
            autofocus
        ></v-textarea>
        <div class="text-right">
          <v-btn color="primary" @click="ask" :disabled="!query.length"><v-icon left>mdi-chat</v-icon>Realizar consulta</v-btn>
        </div>
      </v-col>
      <v-divider></v-divider>
      <v-col
          cols="12"
          md="6"
      >
        <v-textarea
            name="response"
            label="Respuesta"
            v-model="response"
            outlined
            no-resize
            readonly
            auto-grow
            :loading="waiting"
            :disabled="!response.length"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import api from "@/api";

export default {
  name: "IAChatView",
  data() {
    return {
      query: '',
      response: '',
      waiting: false
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
  },
  methods: {
    ask() {
      this.waiting = true
      api.ASK_TO_GPT(this.query)
          .then(response => {
            this.response = response.data.choices[0].message.content
            this.waiting = false
          })

    }
  }
}
</script>

<style scoped>

</style>
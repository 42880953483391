<template>
  <div class="container-fluid">
    <div class="d-inline-flex">
      <img :src="'https://infotechnic.tecnoclub.org/logos/'+vehicle.maker.toLowerCase()+'.png'" alt=""
           class="img-fluid mr-2"
           style="height: 50px">
      <h1>{{ vehicle.maker }} {{ vehicle.model }} {{ vehicle.motor }}</h1>
    </div>
    <h2 class="card-subtitle">{{ categoryName }}</h2>
    <hr class="mt-1">
    <div class="row">
      <div class="col-12 col-md-4">
        <label class="mb-1 d-block d-md-none">{{TEXT('select_bulletin')}}:</label>
        <select class="custom-select custom-select-sm d-block d-md-none mb-4 bg-primary text-white"
                v-model="bulletin">
          <option v-for="(b, index) in filtered" :value="index" :key="index">{{ b.title }}</option>
        </select>
        <select class="form-control d-none d-md-block" :size="filtered.length" v-model="bulletin">
          <option v-for="(b, index) in filtered" :value="index" :key="index">{{ b.title }}</option>
        </select>
      </div>
      <div class="col-12 col-md-8">
        <template v-if="filtered.length > 0">
          <bulletin-viewer :bulletin="filtered[bulletin]" v-if="bulletins.length > 0"></bulletin-viewer>
          <br>
          <p style="color:white">{{ filtered[bulletin].bulletin_id }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import {mapGetters, mapState} from "vuex";
import BulletinViewer from "@/components/bulletins/BulletinViewer.vue";

export default {
  name: "BulletinSelector",
  components: {BulletinViewer},
  props: ['customList'],
  data() {
    return {
      bulletins: [],
      bulletin: 0
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'vehicle',
      'category',
      'categoryName'
    ]),
    filtered() {
      if (this.bulletins.length > 0) {
        return this.bulletins.filter(item => {
          return item.category == this.category
        })
      } else {
        return []
      }
    }
  },
  methods: {
    listBulletins() {
      api.GET_BULLETINS(this.vehicle.vehicleID)
          .then(response => {
            if (!response.data.auth) {
              this.logout()
            } else {
              this.bulletins = response.data.list
            }
          })
    },
  },
  created() {
    if (this.customList && this.customList.length > 0) {
      this.bulletins = this.customList
    } else {
      this.listBulletins();
      if (!this.category) {
        this.$store.commit('CHANGE_TSB_VIEW', 0)
      }
    }
  }

}
</script>

<style scoped>

</style>
<template>
  <span>
    <a
        v-for="lang in languages"
        @click="$store.commit('CHANGE_LANGUAGE', lang)"
        :class="'mr-2 fi fi-'+lang"
        :key="lang">
    </a>
  </span>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LanguageSelector",
  data() {
    return {
      languages: ['es', 'us', 'pt', 'fr']
    }
  },
  computed: {
    ...mapState([
      'language',
    ]),
  },

}
</script>

<style scoped>
a{
  text-decoration: none !important;
}
</style>